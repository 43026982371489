import styled from 'styled-components';

interface PropsShow {
  show:boolean;
}

export const Container = styled.div<PropsShow>`
  /* padding-bottom: 4rem; */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  

  .wrapper {
    width: 90vw;
    max-width: 1920px;
    margin: 0 auto;
    padding: 2% 4%;
    margin: 0 auto;
    /* min-height: 100vh; */

    .sub-infos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -10px;
      .location {
        text-decoration: none;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0;
        cursor: pointer;
        margin: 0.5rem 0.5rem 0.5rem 0;
        color: var(--backgroud-button-component);
        opacity: .75;

        @media screen and (max-width:800px){
          font-size: .8rem;
        }
      }
      .share-favorite {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        /* p{
          display: flex;
          align-items: center;
          gap: 0.2rem;
          transition: 0.3s;
          cursor: pointer;
          :hover {
            text-decoration: underline;
          }
        } */
        .icon-add {
          /* z-index: 2000000000; */
          font-size: 1.2rem;
          color: var(--background-button-component);
          background-color: var(--bg-color);
          padding: 1px;
          border-radius: 20px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            filter: brightness(0.7);
          }
        }

        .add-destaque {
          /* z-index: 2000000000; */
          font-size: 1.125rem;
          color: var(--background-button-component);
          background-color: var(--bg-color);
          padding: 1.1px;
          border-radius: 20px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            filter: brightness(0.7);
          }
        }

        .add-destaque-golden {
          /* z-index: 2000000000; */
          font-size: 1.125rem;
          color: var(--primary-gold);
          background-color: var(--bg-color);
          padding: 1.1px;
          border-radius: 20px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            filter: brightness(0.7);
          }
        }
      }
    }
  }

  .wrapper h1 {
    font-weight: 500;
    margin: 0 0 1.3rem 0;

    @media screen and (max-width:800px){
      font-size: 1.2rem;
    }
  }

  .wrapper .box-images {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
    height: 454px;
    /* margin-bottom: 2rem; */

    .image-select {
      cursor: pointer;
    }
    
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 454px;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      background-color: rgba(0,0,0, .2);

      svg {
        font-size: 4rem;
        color: #777;
      }
      
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        object-fit: cover;
      }
    }
    
    .right {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: .5rem;
      width: 100%;
      height: 454px;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      overflow: hidden;
      
      .bg-color1,
      .bg-color2,
      .bg-color3,
      .bg-color4 { 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        /* background-color:s rgba(0,0,0, .1);  */

        svg {
          font-size: 4rem;
          color: #777;
        }

        img {
          width: 100%;
          height: 223px;
          object-fit: cover;
        }
      }
    }

    .view-images-mobile {
      display: none;
      width: 100%;
      height: 250px;
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .more-images {
      position: absolute;
      right: 30px;
      bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;

      padding: 5px 1rem;
      color: #444;
      border-radius: .5rem;
      cursor: pointer;
      background-color: #eceff1;
      border: 1px solid #111;
      transition: all .2s ease;
      
      &:hover {
        background-color: #cfd8dc;
      }

      p {
        font-size: .8rem;
        font-weight: bold;
        margin: 0;
      }
    }

    @media screen and (max-width: 800px) {
      display: flex;
      height: auto;
      .left {
        display: none !important;
      }
      .right {
        display: none;
      }
      .view-images-mobile {
        display: flex;
        height: auto;
      }
      .more-images {
        display: none;
      }
    }
  }

  .icons-share {
    display: flex;
    justify-content: flex-start;
    margin: 0.5rem 0 0;
    gap: 1rem;
    opacity: .85;

    .icon-and-text {
      display: flex;
      gap: 0.5rem;
      cursor: pointer;
      text-decoration: none;
      color: var(--background-button-component);
      
      p{
        font-size: 0.9rem;
      }
      .icon {
        font-size: 1rem;
      }
    }

    @media screen and (max-width:800px){
      .icon-and-text {
        p{
          font-size: 0.7rem;
        }
      }
    }
  }

  .price-mobile {
    display: none;
    width: 100%;
    margin: 1rem 0 0 0;

    .value-property {
      width: 100%;
        .sub {
          text-align: right;
          font-weight: 500;
          font-size: 0.8rem;
          color: var(--color-title-home);
        }
        .value {
          text-align: right;
          font-size: 2.2rem;
          font-weight: 600;
          margin-bottom:1rem;
          line-height: 22px;
        }
        .traco {
          width: 100%;
          height: 1px;
          margin-top: 2rem;
          background-color: var(--background-footer);
          opacity: .6;
        }
      }

    @media screen and (max-width:800px){
      display: flex;
    }
  }

  .wrapper .informations {
    display: flex;
    gap: 2.5rem;
    margin-bottom:4rem;
    margin-top: 2rem;

    @media screen and (max-width:800px){
      margin-top:1rem;
    }

    .desc-left {
      width: 70%;
      /* padding-right: 1rem; */

      @media screen and (max-width:800px){
        padding-right: 0;
      }

      .relacionados {
        padding-right: 1rem;
        

        .container-with-dots {
          z-index: 2;
        }

        h3 {
          font-weight: 600;
          margin-bottom: 1.8rem;
        }
      }

      .img-and-button {
        width: 100%;
        border: 1px solid var(--gray-one);
        padding: 1rem 1rem 1rem 0.5rem;
        border-radius: 10px;
        display: flex;
        flex-direction: row;

        img {
          width: 230px;
          margin-bottom: -5rem;
        }

        .texts {
          .text-1 {
            margin: 0;
            font-weight: 800;
            font-size: 1.5rem;
            color: var(--background-button-component);
          }
          .text-2 {
            font-weight: 700;
            color: var(--color-font-footer);
          }
        }

        .button-simulation {
          display: flex;
          justify-content: center;
          
          button {
            background-color: transparent;
            padding: 0.5rem 3rem;
            font-size: 0.8rem;
            border: 1px solid var(--color-font-footer);
            border-radius: 6px;
            cursor: pointer;
            transition: .3s;

            &:hover {
              background-color: var(--background-button-component);
              color: white;
            }
          }
        }

        @media screen and (max-width:800px){
          align-items: center;
          justify-content: center;
          img {
            display: none;
            margin-bottom: 0;
          }
          .texts{
            .text-1 {
              font-size:1rem;
              text-align:center;
            }
            .text-2 {
              font-size:.8rem;
              text-align:center;
            }
          }
          .button-simulation {
            button {
              font-size: .6rem;
            }
          }
        }
      }

      h3 {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 5px;

        span {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: rgba(0,0,0, .2);
        }
      }
    }

    .desc-left
    .box-desc,
    .overview,
    .address,
    .resources,
    .location,
    .announcement {
      /* padding-right: 1rem; */
      margin-bottom: 1.5rem;
      /* border-bottom: 1px solid rgba(0,0,0, .09); */

      h3 {
        font-weight: 600;
        margin-bottom: 1.8rem;
      }
    }

    .desc-left .box-desc .about-imovel {
      color: #555;

      p {
        font-size: .9rem;
        margin-bottom: 1.5rem;
        line-height: 22px;
      }
    }

    .overview .items,
    .resources .resources-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media screen and (max-width:800px){
        grid-template-columns: repeat(2, 1fr);
        gap: .8rem;
      }
    }

    .overview .items .item-single  {
      display: flex;
      /* gap: 1rem; */
      margin-bottom: 0rem;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        width: 50px;
        height: 50px;
        color: var(--background-button-component);
        /* border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0,0,0, .09); */

        @media screen and (max-width:800px){
          width: auto;
          height: auto;
          font-size: 1.2rem;
        }
      }

      .item-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width:800px){
          margin: 0 0 0 0.5rem;
        }

        p:nth-child(1) {
          font-size: .9rem;
          font-weight: 500;
          margin-bottom: 4px;
          color: var(--background-button-component);

          @media screen and (max-width:800px){
            font-size: .8rem;
            margin:0 0 0.3rem;
          }
        }
        p:nth-child(2) {
          font-size: .8rem;
          margin-bottom: 0px;
          font-weight: 600;
          color: var(--background-button-component);
          opacity: .75;

          @media screen and (max-width:800px){
            font-size: .65rem;
            margin:0;
          }
        }
      }
    }

    .address-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    .address-wrapper .info-single {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      p {
        font-weight: 500;
      }

      span {
        color: #777;
      }
    }

    .resources .resources-wrapper {
      color: #777;
      padding: 0 0 1rem 0;
    }

    .resources .resources-wrapper .item {
      display: flex;
      align-items: center;
      color: #777;
      img {
        width: 22px;
        margin-right: 10px;
      }
    }

    .location .map {
      height: 500px;
      border-radius: 14px;
      overflow: hidden;
      background-color: rgba(0,0,0, .1);

      iframe {
        border-radius: 8px;
      }
    }

    .announcement {

      .broker-information {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1rem;
        padding: 1rem 1.5rem;
        /* border: 1px solid red; */
        /* height: 100px; */

        .image {
          width: 95px;
          height: 95px;
          border-radius: 50%;
          /* background-color: rgba(0,0,0, .1); */
          cursor: pointer;

          img {
            width: 95px;
            height: 95px;
            border-radius: 50px;
            object-fit: cover;
          }

          .avatar {
            font-size: 95px;
            color: var(--background-button-component);
          }

        }

        .name {
          h3{
            margin:0;
            cursor: pointer;
            color: var(--background-button-component);

            .view-phone-corrector {
            }
          }

          p {
            color: #777;
            font-size: 0.8rem;
            margin:0.5rem 0 0 0;
            font-weight: 600;
          }

          .phone {
            color: var(--background-button-component);
            font-size: 1rem;
            display: flex;
            align-items: center;

            .avatar-phone {
              font-size: 1.5rem;
              margin: 0 0.2rem 0 0;
            }
          }

          .phone-button {
            color: var(--background-button-component);
            font-size: 1rem;
            display: flex;
            align-items: center;
            font-weight: 600;
            background-color: transparent;
            border: none;
            cursor: pointer;

            .avatar-phone {
              font-size: 1.5rem;
              margin: 0 0.2rem 0 0;
            }
          }
        } 
      }
    }
    
    .desc-right {
      width: calc(30% - 2.5rem);

      .div-calendar {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        border: none;

        .calendar {
          border: none;

          .react-calendar__tile--range {
            background-color: var(--background-button-component);
            
          }
          .react-calendar__tile--now {
            background: var(--gray-one);
            
          }
          .react-calendar__tile:enabled:hover{
            background-color: #e6e6e6;
            
          }
          .react-calendar__tile:enabled:focus {
            background-color: var(--background-button-component);
            
          }
        }
      }

      .div-button-calendar {
        display: flex;
        justify-content: flex-end;

        button {
          margin-right: 2rem;
          border: none;
          background-color: var(--background-button-component);
          padding: 0.5rem 1rem;
          color: white;
          font-size: 0.8rem;
          border-radius: 6px;
          cursor: pointer;
          transition: .3s;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }

      .value-property {

        @media screen and (max-width:800px){
          display: none;
        }

        .sub {
          text-align: right;
          font-weight: 500;
          color: var(--color-title-home);
        }
        .value {
          text-align: right;
          font-size: 2.5rem;
          font-weight: 600;
          margin-bottom:1rem;
          line-height: 22px;
        }
        .traco {
          width: 100%;
          height: 1px;
          margin-top: 2rem;
          background-color: var(--background-footer);
        }
      }
    }

    .desc-right-wrapper {
      position: sticky;
      transition: 1s ease;
      top: ${({show})=> show ? '1rem' : '6rem'};

      @media screen and (max-width:800px){
        /* margin-top: .3rem; */
        position: relative;
        top:1rem;
      }
    }

    .desc-right .desc-right-wrapper,
    .simulate-financing,
    .highlights {
      margin-top: 2rem;
      border-radius: 20px;
      border: 1px solid rgba(0,0,0, .1);
      box-shadow: 0px 2px 4px rgba(0,0,0, .09);
      
      h4 {
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    .desc-right .desc-right-wrapper .form,
    .simulate-financing .form {

      input {
        width: 100%;
        height: 50px;
        padding-left: 1rem;
        margin-bottom: 2rem;
        border: 1px solid rgba(0,0,0, .09);
        border-radius: 6px;
        background-color: #f8f8f8;

        &::placeholder {
          color: #ABABAB;
        }
      }

      .text-and-check {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        margin: 0 0 1rem;
        
        p {
          font-size: 0.8rem;
          margin: 0;
          color: var(--color-font-footer);
        }
        .check {
          height: 15px;
          width: 15px;
          margin: 0 0 0 5px;
          border: 1px solid var(--color-font-footer);
          color: var(--background-button-component);

          input[checked] {
            background-color: var(--background-button-component);
          }
        }
      }
    }

    .desc-right .desc-right-wrapper .form,
    .simulate-financing .form {
      padding: 1rem;

      .calendar {
        border: none;
        margin-bottom: 1rem;
        border-radius: 6px;
        /* background-color: #f8f8f8; */
        .react-calendar__tile--range {
            background-color: var(--background-button-component);
            
        }
        .react-calendar__tile--now {
          background: var(--gray-one);
          
        }
        .react-calendar__tile:enabled:hover{
          background-color: #e6e6e6;
          
        }
        .react-calendar__tile:enabled:focus {
          background-color: var(--background-button-component);
          
        }
      }

      textarea {
        width: 100%;
        min-height: 120px;
        padding-top: .8rem;
        padding-left: 1rem;
        margin-bottom: 0.8rem;
        border: 1px solid rgba(0,0,0, .09);
        border-radius: 6px;
        background-color: #f8f8f8;

        &::placeholder {
          color: #ABABAB;
        }
      }

      h4 {
        margin: 1rem 0 1.5rem 0 ;
      }

    }
    
    .desc-right .highlights {
      padding: 0 1rem 1rem 1rem;
    }
    .desc-right .highlights .highlight-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 220px;
      padding: .7rem;
      border-radius: 15px;
      background-color: rgba(0,0,0, .2);
      box-shadow: inset 0px -55px 32px rgba(0,0,0, .5); 

      .info-highlights {
        display: flex;
        gap: .5rem;

        .orange {
          background-color: #B15636;
        }
        .bold-green {
          background-color: #153B3E;
        }

        .premium, .launch {
          font-size: .8rem;
          color: #fff;
          padding: 5px 8px;
          border-radius: 4px;
        }
      }

      .informations-price {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        color: #fff;

        p {
          font-weight: 900;
        }
      }
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;

      .desc-left {
        width: 100%;
      }
      .desc-right {
        width: 100%;
      }
    }
    
  }
`;